import { useState } from "react"
import styled from "styled-components"

import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core"

import { useTranslate } from "src/i18n/useTranslate"
import { systemEmergencyForeground } from "src/ui/colors"
import PenIcon from "src/ui/icons/edit.svg"
import OptionsIcon from "src/ui/icons/options-horizontal.svg"
import TrashIcon from "src/ui/icons/trash.svg"
import { spacing } from "src/ui/spacing"

type IClickEvent<E extends HTMLElement = HTMLElement> = React.MouseEvent<
  E,
  MouseEvent
>

interface IMenuItems {
  key: string
  contents: React.ReactNode
  onClick: null | ((e?: IClickEvent<HTMLLIElement>) => void)
  hidden?: boolean
}

export function MoreButton({
  onEdit,
  onDelete,
  menuItems,
  deleteLabel,
  showDeleteIcon = true,
  disabled,
}: {
  onEdit?: () => void
  onDelete?: () => void
  menuItems?: IMenuItems[]
  deleteLabel?: React.ReactNode
  showDeleteIcon?: boolean
  disabled?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const popoverId = "more-button"

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)

  function handleMoreClick(e: IClickEvent<HTMLButtonElement>) {
    e.stopPropagation()
    const anchor: HTMLButtonElement = e.currentTarget
    setAnchorEl(anchor)
    setMenuOpen(true)
  }

  function handleDelete(e: IClickEvent) {
    e.stopPropagation()
    onDelete?.()
    if (anchorEl) {
      setMenuOpen(false)
    }
  }

  function handleEdit(e: IClickEvent) {
    e.stopPropagation()
    onEdit?.()
    if (anchorEl) {
      setMenuOpen(false)
    }
  }

  function handleClose(e: IClickEvent) {
    e.stopPropagation()
    setMenuOpen(false)
  }

  return (
    <>
      <Menu
        id={popoverId}
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={handleClose}
      >
        {menuItems?.map((item, index) => {
          if (!item.contents || item?.hidden) return null

          if (!item.onClick) {
            return <ListItem key={item.key}>{item.contents}</ListItem>
          }

          return (
            <MenuItem
              button
              key={item.key}
              onClick={(e) => {
                item.onClick?.(e)
                handleClose(e)
              }}
            >
              {item.contents}
            </MenuItem>
          )
        })}

        {!!onEdit && (
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <PenIcon height="24" width="24" />
            </ListItemIcon>
            <ListItemText primary={t(langKeys.edit)} />
          </MenuItem>
        )}

        {!!onDelete && (
          <MenuItem onClick={handleDelete}>
            {showDeleteIcon && (
              <ListItemIcon>
                <TrashIcon
                  height="24"
                  width="24"
                  fill={systemEmergencyForeground}
                />
              </ListItemIcon>
            )}
            <LisItemDeleteText primary={deleteLabel || t(langKeys.remove)} />
          </MenuItem>
        )}
      </Menu>

      <StyledIconButton
        aria-label="more-button"
        aria-describedby={popoverId}
        onClick={handleMoreClick}
        disabled={disabled}
        color="inherit"
      >
        <OptionsIcon width={24} height={24} />
      </StyledIconButton>
    </>
  )
}

const StyledIconButton = styled(IconButton)`
  padding: ${spacing.XS};
`

const LisItemDeleteText = styled(ListItemText)`
  color: ${systemEmergencyForeground};
`
